<template>
  <div></div>
</template>
<script>
export default {
  mounted() {
    if (this.$store.getters.shareBrokerPeopleOptions.length)
      this.$router.push('/handover/share').catch((error) => {
        console.log(error)
      })
    else
      this.$router.push('/handover/account').catch((error) => {
        console.log(error)
      })
  }
}
</script>
